$turquoise: #79C0C6;
$dark-blue: #32446A;
$light-blue: #B5C9DA;
$grey-blue: #E1E8E8;
$grey: #949494;

$primary-font: 'Source Sans Pro', sans-serif;

// breakpoints
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
