*, *:before, *:after {
  box-sizing: border-box;
}

body {
  font-family: $primary-font;
}

strong {
  font-weight: 700;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.ital {
  font-weight: 600;
  font-style: italic;
}
