main {
  h1 {
    color: $light-blue;
  }
  p {
    color: #fff;
  }
}
#past-performances {
  background-color: $grey-blue;
  padding-top: 84px;
  padding-bottom: 84px;
  h2 {
    font-size: 32px;
    line-height: 100%;
    font-weight: 700;
    text-align: center;
    margin-bottom: 150px;
    @media screen and (max-width: $screen-sm) {
      margin-bottom: 20px;
    }
  }
}
