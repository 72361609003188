.marquee {
  // display: flex;
  text-align: center;
  background-color: $turquoise;
  justify-content: center;
  color: $dark-blue;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 120%;
  a {
    color: $dark-blue;
  }
}
.header {
  background-color: $dark-blue;
  color: $light-blue;
  padding-top: 34px;
  padding-bottom: 34px;
  padding-left: 15px;
  padding-right: 15px;
  a {
    color: $light-blue;
  }
  .container {
    display: flex;
    justify-content: space-between;
  }
}
.title {
  font-size: 24px;
  letter-spacing: 2px;
}
.ss-logo {
  padding-top: 26px;
  padding-bottom: 22px;
  max-width: 100%;
}
