form {
  font-size: 0;
}
input[type='text'] {
  background-color: #fff;
  font-size: 17px;
  font-family: $primary-font;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 28px;
  border: 0;
  width: 294px;
  @media screen and (max-width: $screen-sm) {
    width: 100%;
    margin-bottom: 15px;
  }
}
input[type='submit'] {
  width: 145px;
  color: #fff;
  font-size: 24px;
  background-color: $turquoise;
  font-size: 17px;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 0;
  margin: 0;
  font-family: $primary-font;
  cursor: pointer;
  border: 1px solid $turquoise;
  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }
}
