.card-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-right: 15px;
}
.card {
  background-color: #fff;
  box-shadow: 0px 2px 4px #C9C9C9;
  width: 400px;
  font-size: 20px;
  padding: 22px 35px;
  margin: 0 8px;
  margin-bottom: 20px;
  cursor: pointer;
  .about & {
    cursor: none;
  }
  @media screen and (max-width: $screen-lg) {
    width: calc(50% - 16px);
    margin-top: 20px;
  }
  @media screen and (max-width: $screen-sm) {
    width: calc(100% - 16px);
  }
}

.card-header {
  font-weight: 600;
  letter-spacing: 1.43px;
  margin-bottom: 18px;
}

.card-venue-info p {
  color: #949494;
  font-weight: 400;
  line-height: 25px;
}

.see-more {
  text-align: center;
  width: 100%;
  p {
    color: #6B6B6B;
    font-size: 24px;
    margin-bottom: 18px;
  }
  img {
    display: block;
    margin: 0 auto;
  }
}
