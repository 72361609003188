.hero {
  background: url('../images/ss-hero.jpg');
  background-size: cover;
  background-position: center center;
  padding-top: 22px;
  padding-left: 22px;
  padding-right: 22px;
  h1 {
    display: block;
  }

  p {
    font-size: 22px;
    line-height: 120%;
    padding-bottom: 20px;
  }

  p.presents {
    font-size: 27px;
    padding-top: 5px;
  }

  form {
    padding-top: 37px;
    padding-bottom: 65px;
  }
}

.hero-wrap {
  max-width: 930px;
  margin: 0 auto;
  text-align: center;
}

.ns-logo {
  padding-top: 5px;
  width: 208px;
}
