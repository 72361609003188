.credits {
  padding-top: 90px;
  padding-bottom: 90px;
  margin-left: 22px;
  margin-right: 22px;
  max-width: 950px;
  margin: 0 auto;
  p {
    line-height: 140%;
  }
  @media screen and (max-width: $screen-sm) {
    margin-left: 22px;
    margin-right: 22px;
  }
  a, a:visited {
    color: $turquoise;
  }
  h3 {
    font-size: 30px;
    color: $dark-blue;
    font-weight: 700;
    padding-bottom: 30px;
  }
  p {
    color: #000;
    font-size: 18px;
    padding-bottom: 36px;
  }
}
.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.logo {
  @media screen and (max-width: $screen-sm) {
    display: block;
    width: 100%;
    padding-top: 11px;
    padding-bottom: 11px;
  }
}
.logo-cac {
  width: 330px;
}
.logo-metcalf {
  width: 190px;
}
.logo-oac {
  width: 260px;
}
.logo-tac {
  width: 100px;
}
