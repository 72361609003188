.button {
  color: #fff;
  font-size: 22px;
  display: inline-block;
  line-height: 100%;
  padding-top: 21px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 21px;
  @media screen and (max-width: ) {
    font-size: 17px;
  }
}

button.button {
  border: 0;
}

.button--dark-blue {
  background-color: $dark-blue;
}

.button--grey-blue {
  background-color: $grey-blue;
  color: $dark-blue;
}

.button--turquoise {
  background-color: $turquoise;
  padding: 0;
  font-size: 14px;
  letter-spacing: 0px;
  padding: 7px 10px;
}

.removebutton {
  color: #d8d8d8;
  background-color: #fff;
  border: 0;
  cursor: pointer;
  font-size: 14px;
}
