.about {
  .hero {
    background-image: -webkit-linear-gradient(left, #79C0C6 0%, #32446A 95%);
    background-image: -o-linear-gradient(left, #79C0C6 0%, #32446A 95%);
    background-image: linear-gradient(to right, #79C0C6 0%, #32446A 95%);
    h1 {
      padding-top: 66px;
      padding-bottom: 22px;
      color: $light-blue;
      font-size: 34px;
      letter-spacing: 2.83px;
      font-weight: 600;
    }
  }
  .hero-wrap {
    text-align: left;
    padding-bottom: 108px;
  }
  p {

  }
  #past-performances {
    h2 {
      color: $dark-blue;
      font-weight: 400;
      margin-bottom: 61px;
    }
    strong {
      font-weight: 600;
      font-style: italic;
    }
    .card-date {
      padding-bottom: 20px;
    }
    .card-venue-info {
      padding-bottom: 30px;
      p {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0;
      }
    }
  }
}
