footer {
  display: flex;
  flex-wrap: wrap;

  .left-column {
    background-color: $turquoise;
    width: 50%;
    @media screen and (max-width: $screen-sm) {
      width: 100%;
    }
  }
  .left-column.footer-cta, .right-column.footer-cta {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
    h3 {
      text-align: center;
    }
  }
  .right-column {
    background-color: $dark-blue;
    width: 50%;
    @media screen and (max-width: $screen-sm) {
      width: 100%;
    }
  }
  .copyright {
    width: 70%;
    color: $dark-blue;
    p {
      padding-bottom: 10px;
    }
    a {
      color: $dark-blue;
    }
    a:visited {
      color: $dark-blue;
    }
    @media screen and (max-width: $screen-sm) {
      order: 2;
    }
  }
  .social {
    width: 30%;
    display: flex;
    @media screen and (max-width: $screen-sm) {
      order: 1;
    }
  }
  .social, .copyright {
    padding-top: 30px;
    padding-bottom: 26px;
    .left-column, .right-column {
      background-color: $grey-blue;
    }
    @media screen and (max-width: $screen-sm) {
      width: 100%;
    }
  }
  .trademark-wrapper {
    width: 100%;
    background-color: $grey-blue;
    .container {
      display: flex;
      width: 100%;
      @media screen and (max-width: $screen-md) {
        flex-wrap: wrap;
        text-align: center;
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }
}
.footer-cta {
  h3 {
    color: #fff;
    font-size: 26px;
    padding-bottom: 28px;
    @media screen and (max-width: $screen-sm) {
      text-align: center;
    }
  }
}
.social {
  .left-column {
    a, a:visited {
      color: #79C0C6;
      line-height: 150%;
      font-weight: 600;
    }
    @media screen and (max-width: $screen-sm) {
      text-align: right;
      padding-right: 15px;
    }
  }
  .right-column {
    img {
      padding-right: 22px;
    }
    @media screen and (max-width: $screen-sm) {
      text-align: left;
      padding-left: 15px;
    }
  }
}
