.jukebox-container {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 80px;
  display: none;
  flex-wrap: wrap;
  @media screen and (max-width: $screen-lg) {
    margin: 0 45px;
  }
}

#result {
  width: 60%;
  @media screen and (max-width: $screen-lg) {
    width: 100%;
  }
  iframe {
    max-width: 100%;
  }
}

#playlist-container {
  width: 40%;
  border-left: 1px solid #979797;
  padding-left: 70px;
  @media screen and (max-width: $screen-lg) {
    width: 100%;
    padding-left: 0;
    padding-top: 45px;
    border-top: 1px solid #979797;
    border-left: 0px;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 37px;
  }
  h3 {
    font-style: italic;
    font-weight: 400;
    color: $grey;
  }
}

.playlist-songcontainer {
}

.song-title-container {
  display: flex;
  padding-top: 86px;
  @media screen and (max-width: $screen-lg) {
    padding-bottom: 45px;
    flex-wrap: wrap;
    padding-top: 45px;
  }
}

.song-title {
  width: 60%;
  font-size: 40px;
  color: $dark-blue;
  padding-right: 20px;
  @media screen and (max-width: $screen-lg) {
      width: 100%;
      margin-bottom: 32px;
  }
}
.playlist-songcontainer {
  display: flex;
  margin-top: 25px;
  padding-top: 13px;
  border-top: 1px solid #BCBCBC;
  align-items: center;
  img {
    margin-right: 15px;
  }
  &:last-of-type {
    border-bottom: 1px solid #BCBCBC;
    padding-bottom: 13px;
  }
}
.playlist-button-container a {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 17px;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  img {
    margin-right: 15px;
  }
}
#playlist-container h3.playlist-songtitle {
  color: #000;
  font-weight: 700;
  font-style: normal;
  padding-right: 30px;
}
