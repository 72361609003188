.modal {
  position: fixed;
  display: none;
  top: calc(50% - 153px);
  bottom: 0;
  left: calc(50% - 340px);
  box-shadow: 4px 6px 15px rgba(50, 68, 106, 100);
  right: 0;
  width: 680px;
  height: 306px;
  background-color: #fff;
  font-size: 22px;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-left: 45px;
  padding-right: 45px;
  line-height: 36px;
  a {
    color: $turquoise;
    &:visited {
      color: $turquoise;
    }
  }

}
.modal-close {
  background: none;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 35px;
  right: 40px;
}
